<template>
    <div v-if="isMounted">
      <!-- BANNER DE NIVEL DE CONTRATO -->
      <div v-if="contractLevel == 3 && showBalance" class="vx-row">
         <div class="vx-col w-full mb-sub-title">
            <vx-card class="sub-title-card">
               <h1 class="rg-sub-title extrabold">
                     Tu saldo actual es de {{balance}} MXN.
               </h1>
            </vx-card>
         </div>
      </div>
      <div v-if="contractLevel == 1 || contractLevel == 2" class="vx-row">
         <div class="vx-col w-full mb-sub-title">
            <vx-card class="sub-title-card">
               <h1 class="rg-sub-title extrabold">
                     Tu saldo actual es de {{balance}} MXN.
               </h1>
               <div class="vx-row">
               <vs-divider></vs-divider>
                     <div class="vx-col w-full md:w-3/4">                      
                        <div class="flex">
                           <div class="flex mr-5">
                              <vs-avatar color="#CCFC00" icon-pack="feather" size="15px" />
                              <div class="vx-col w-full">
                                    <label class="mt-5"><strong> Monto operado:</strong> {{currentMonthlyOperationAmount}}</label><br>
                              </div>
                           </div>
                           <div class="flex">
                              <vs-avatar color="#92B9B0" icon-pack="feather" size="15px" />
                              <div class="vx-col w-full">
                                    <label class="mt-5"><strong> Disponible para operar:</strong> {{availableAmountToOperate}}</label><br>
                              </div>
                           </div>
                        </div>
                        <vs-progress class="mt-5" :height="20" :percent="monthlyOperationPercentage" color="#CCFC00"></vs-progress>
                     </div>
                     <div class="vx-col w-full md:w-1/4">
                        <vs-button
                           v-if="contractLevel == 1"
                           class="mt-6"
                           id="raise_limit_btn"
                           color="success"
                           type="filled"
                           @click.native="goToIncrementLevel"
                           >Aumentar límite</vs-button>
                     </div>
                     <div class="vx-row">
                        <div class="vx-col w-full">
                           <div class="flex">
                              <feather-icon icon="InfoIcon" svgClasses="w-4 h-4 text-dark" class="ml-4 mr-2" />
                              <contract-levels-info 
                                 :current-level="contractLevel" 
                                 :monthly-limit-amount-one="contractLevelData.monthly_limit_1"
                                 :monthly-limit-amount-two="contractLevelData.monthly_limit_2"
                                 :account-limit-one="contractLevelData.account_limit_1"
                                 />
                           </div>
                        </div>
                     </div>
               </div>
            </vx-card>
         </div>
      </div>
      <!-- TERMINA BANNER DE CONTRATO -->
    </div>
  </template>
  <script>
  import formatHelper from '@mixins/formatHelper';
  import currencyHelper from '@mixins/currencyHelper';
  import {VMoney} from 'v-money'
  import {formatPrice, parseValue} from "@/filters";
  import {mapActions, mapState} from "vuex";
  import investorInfoHelper from "@/helpers/investorInfoHelper";
  import ContractLevelsInfo from "@components/investor/contract-levels/ContractLevelsInfo";
  
  export default {
   name: "InvestorContractLevelBanner",
   props: {
      showBalance: { type: Boolean, default: true },
   },
     mixins: [
       formatHelper, 
       currencyHelper, 
       investorInfoHelper],
     directives: {money: VMoney},
    components: {ContractLevelsInfo},
     data(){
        return {
          isMounted: false,
           aliasCurrency: 0,
           amount: 0,
           money: {
              decimal: '.',
              thousands: ',',
              prefix: '',
              suffix: '',
              precision: 0,
              masked: false
           },
          contractLevelData: null,
        }
     },
    computed: {
      ...mapState("auth", ["roleGeneralData"]),
      balance(){
        return this.explicitMoneyFormat(this.roleGeneralData.balance);
      },
      contractLevel(){
        return this.contractLevelData.contract_level;
      },
      currentMonthlyOperationAmount(){
        return this.explicitMoneyFormat(this.contractLevelData.monthly_operation_amount);
      },
      monthlyOperationPercentage(){
        return (this.contractLevelData.monthly_operation_amount*100)/this.contractLevelData.monthly_operation_limit_amount;
      },
      availableAmountToOperate(){
        return this.explicitMoneyFormat(this.contractLevelData.monthly_operation_limit_amount-this.contractLevelData.monthly_operation_amount);
      },
      currentLimitOperationAmount(){
        if (this.contractLevelData.monthly_operation_limit_amount == -1) {
          return "Ilimitado";
        }else {
          return this.explicitMoneyFormat(this.contractLevelData.monthly_operation_limit_amount);
        }
      },
      nextLimitOperationAmount(){
        if (this.contractLevelData.next_monthly_operation_limit_amount == -1) {
          return "de forma ilimitada";
        }else {
          return "hasta "+this.explicitMoneyFormat(this.contractLevelData.next_monthly_operation_limit_amount);
        }
      }
    },
    async beforeMount(){
      this.showLoading(true)
      this.isMounted = false
      await this.getInvestorContractLevelData(this.InvestorId);
      await this.getInvestorGeneralData(this.InvestorId);
      this.isMounted = true
      this.showLoading(false)
    },
    watch: {
      aliasCurrency: function(nval, oldval){
        this.amount = this.unCurrencyFormat(nval);
      },
    },
    methods: {
      ...mapActions('auth', ['getInvestorGeneralData']),
      parseValue,
      formatPrice,
      async getInvestorContractLevelData(){
        try {
          let res = await axios.get('/api/v2/investor/' + this.InvestorId + '/getContractLevelData');
          this.contractLevelData = res.data;
        } catch (error) {
        }
      },
      async goToIncrementLevel(){
        await this.$router.replace({name: 'incrementInvestorContractLevel'});
      },
    }
  }
  </script>
  
  <style lang="css">
    .voucher-box {
      border: 2px solid rgba(var(--vs-rgblack),1);
      border-radius: 6px;
      padding: .68rem 0.75rem;
    }
  
    .vs-progress--background{
      background-color: #92B9B0 !important;
    }
  
    .regular {
        font-family: "gilroyregular" !important;
        font-weight: normal;
        line-height: 1.2;
        color: #2c2c2c;
    }
  
    .vx-card.border-primary{
        border-style: solid;
        border-width: 2px;
    }
  </style>
  
  <style lang="scss">
  #data-list-card-view-funds {
    .vs-con-table {
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 10px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
            &.img-container {
  
              span {
                display: flex;
                justify-content: flex-start;
              }
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  