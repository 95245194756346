<template>
  <div>
    <vx-card class="mt-3">
      <div class="vx-row">
        <div class="vx-col w-full mb-1">
          <p class="text-lg bold mb-2">¿Cuáles son tus metas?</p>
          <p class="text-sm">
            ¿Ya sabes cuáles son tus metas conforme al impacto ambiental que quieras lograr
            o a las ganancias que deseas obtener a lo largo de tu participación?
            <span class="bold">¡Descúbrelo!</span>
          </p>
        </div>
      </div>
      <div class="vx-row mt-3 m-0">
        <div class="vx-col w-full p-0">
          <vs-tabs v-model="activeTab" alignment="fixed" color="primary" class="goals-simulation-tabs">
            <vs-tab label="Impacto">
              <div class="vx-row">
                <div class="vx-col w-full mt-4">
                  <p class="text-sm">
                    Ingresa la cantidad de toneladas de CO2 que deseas evitar en el planeta y descubre
                    un aproximado de cuanto tendrías que invertir en energía limpia para lograrlo.
                  </p>
                </div>
                <div class="vx-col w-full flex items-center flex-grow justify-left mt-5">
                  <vs-input
                    class="investment-input w-full"
                    type="text"
                    v-model.lazy="impactInvestment"
                    v-money="impactInvestment !== null || impactInvestment === 0 || impactInvestment === '$0' ? tons : null"
                    name="impactInvestment"
                    v-validate="'required'"
                    :min="1"
                    autocomplete="off"
                    :placeholder="impactPlaceholder" />
                  <vs-button class="ml-6 px-6" color="primary" icon-pack="feather" icon="icon-sun" @click="simulateImpact"></vs-button>
                </div>
              </div>
              <div class="vx-row mt-5">
                <div class="vx-col xxl:w-10/12 w-full">
                  <p class="text-sm">
                    Tu impacto actual es de <span class="bold">{{roundedFormat(currentEmissionReduced, 2)}} toneladas CO<sub>2</sub></span>
                    por lo que necesitas invertir:
                  </p>
                  <p class="text-primary text-3xl bold mt-1">{{moneyFormat(impactSimulationData.needed)}}</p>
                  <vs-button class="mt-4" color="primary" @click="goToInvestments">¡Hazlo realidad!</vs-button>
                </div>
              </div>
            </vs-tab>

            <vs-tab label="Inversión">
              <div class="vx-row">
                <div class="vx-col w-full mt-4">
                  <p class="text-sm">
                    Ingresa la cantidad de dinero que deseas recibir mensualmente y descubre un aproximado de
                    cuanto tendrías que invertir en energía limpia para lograrlo.
                  </p>
                </div>
                <div class="vx-col w-full flex items-center flex-grow justify-left mt-5">
                  <vx-input-group class="w-full">
                    <vs-input
                      class="investment-input w-full"
                      type="text"
                      v-model.lazy="newInvestment"
                      v-money="newInvestment !== null || newInvestment === 0 || newInvestment === '$0' ? money : null"
                      name="newInvestment"
                      v-validate="'required'"
                      :min="1"
                      autocomplete="off"
                      :placeholder="moneyPlaceholder" />
                  </vx-input-group>
                  <vs-button class="ml-6 px-6" color="primary" icon-pack="feather" icon="icon-dollar-sign" @click="simulateMoney"></vs-button>
                </div>
              </div>
              <div class="vx-row mt-5">
                <div class="vx-col xxl:w-10/12 w-full">
                  <p class="text-sm">
                    Hasta el momento has invertido <span class="bold">{{moneyFormat(totalInvestorInvested)}}</span>
                    por lo que necesitas invertir:
                  </p>
                  <p class="text-primary text-3xl bold mt-1">{{moneyFormat(moneySimulationData.needed)}}</p>
                  <vs-button class="mt-4" color="primary" @click="goToInvestments">¡Hazlo realidad!</vs-button>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>

    </vx-card>
  </div>
</template>

<script>
import {VMoney} from 'v-money'
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
export default {
  name: "InvestorGoalsCard",
  directives: {money: VMoney},
  props: {
    factors: {
      type: Object,
      required: true,
    },
    investorImpacts: {
      type: Object,
      required: true,
    },
    totalInvestorInvested: {
      type: Number,
      required: true,
    }
  },
  mixins: [formatHelper, currencyHelper],
  data(){
    return {
      activeTab: 0,
      newInvestment: null,
      impactInvestment: null,
      moneyPlaceholder: '¿Cuánto quieres al mes?',
      impactPlaceholder: '¿Cuánto quieres impactar?',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 0,
        masked: false
      },
      tons: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      moneySimulationData: {
        amount: 0,
        needed: 0,
      },
      impactSimulationData: {
        amount: 0,
        needed: 0,
      }
    }
  },
  methods: {
    async simulateMoney(){
      const inv = this.unMoneyFormat(this.newInvestment);
      if(inv > 0){
        const am = inv * this.factors.gain_to_investment_factor;
        const n = am - this.totalInvestorInvested;
        this.moneySimulationData.needed = (n > 0) ? n : 0;
      }
    },
    async simulateImpact(){
      const inv = this.unMoneyFormat(this.impactInvestment);
      if(inv > 0){
        this.impactSimulationData.amount = inv;
        const n = inv - this.currentEmissionReduced;
        if(n > 0){
          this.impactSimulationData.needed = n * this.factors.ton_to_money_factor;
        }
        else {
          this.impactSimulationData.needed = 0;
        }
      }
    },
    async goToInvestments(){
      await this.$router.push({name: 'oportunidades'});
    },
  },
  computed: {
    currentEmissionReduced(){
      return this.investorImpacts.environmental / 1000;
    }
  }
}
</script>

<style>
.goals-simulation-tabs .vs-tabs--li button {
  font-family: 'Gilroybold',sans-serif !important;
  font-weight: normal !important;
}

</style>