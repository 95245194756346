<template>
   <div id="inicio" class="user-start-no">
      <div>
         <!-- BANNER BIENVENIDO A LA RED -->
         <!-- <div class="vx-row">
            <div class="vx-col w-full mb-3">
               <vx-card class="big-title-card-bg h-auto">
                  <h1 class="rg-big-title extrabold">
                     ¡Bienvenid@ a la <span>Red</span>!
                  </h1>
               </vx-card>
            </div>
         </div> -->

         <!-- BANNER PREREGISTRO MERCADO SECUNDARIO -->
         <div v-if="isMounted">
            <!-- SI EL INVERSIONISTA YA SE ENCUENTRA EN EL MERCADO SECUNDARIO -->
            <div v-if="InvestorRegisteredOnSecondaryMarket" class="vx-row">
               <div class="vx-col lg:w-2/3 w-full mb-5">
                  <image-overlay bg-url ="https://cdn.redgirasol.com/investors/home/home_banner.png"
                        title="Tu nueva inversión está muy cerca"
                        subtitle="Fecha estimada otoño 2024"
                        min-height="200px"/>
               </div>
               <div class="vx-col lg:w-1/3 w-full mb-5">
                  <vx-card style="min-height: 200px;">
                     <div class="flex flex-wrap justify-between mt-5">
                        <h3 class="bold">Inversión automática</h3>
                        <automatic-investment-button class="mt-1" @automatic-investment-changed="automaticInvestmentChanged"/>
                     </div>
                     <p class="mt-base" style="">Invierte tu dinero automáticamente y crece de manera exponencial.</p>
                  </vx-card>
               </div>
            </div>

            <!-- SI EL INVERSIONISTA NO SE ENCUENTRA EN EL MERCADO SECUNDARIO -->
            <div v-else class="vx-row">
               <div class="vx-col lg:w-1/2 w-full mb-base">
                  <vx-card class="text-center p-5">
                     <full-image-carousel :images="carouselImages" v-if="carouselImages.length > 0" />
                  </vx-card>
               </div>
               <div class="vx-col lg:w-1/2 w-full mb-base">
                  <vx-card style="height: 100%;">
                     <div slot="no-body">
                        <image-overlay bg-url ="https://cdn.redgirasol.com/investors/home/secondary_market_banner.png"
                           title="Únete a la lista de espera y mejora tu inversión"
                           subtitle="Fecha de lanzamiento: otoño 2024"/>
                     </div>
                     <div class="ml-2 mt-2">
                        <h2 class="mb-2">Completa los siguientes pasos ({{ secondaryMarketStepsCount }}/3):</h2>

                        <div v-if="!hasAutomaticInvestmentInactive" class="vx-row mt-base mb-base">
                           <div class="vx-col w-full">
                              <div class="flex flex-wrap items-center">
                                 <div class="flex flex-wrap items-center mb-2">
                                    <feather-icon icon="CheckCircleIcon" svgClasses="w-8 h-8 text-primary mr-2"/>
                                    <h3 class="bold mr-5">Activa la inversión automática</h3>                               
                                 </div>
                                 <!-- boton de inversion automatica -->
                                 <automatic-investment-button @automatic-investment-changed="automaticInvestmentChanged"/>
                              </div>
                              <p class="bold">Mantenla activada para crecer con interés compuesto.</p>
                           </div>
                        </div>

                        <div class="vx-row mt-base">
                           <div class="vx-col w-full">
                              <div class="flex flex-wrap items-center mb-2">
                                 <feather-icon v-if="minInvestmentToSecondaryMarketReached" icon="CheckCircleIcon" svgClasses="w-8 h-8 text-primary mr-2"/>
                                 <feather-icon v-else icon="CheckCircleIcon" svgClasses="w-8 h-8 text-gray mr-2"/>
                                 <h3 class="bold">Alcanza la inversión mínima de $5,000</h3>
                              </div>
                              <p class="bold">
                                 <template v-if="minInvestmentToSecondaryMarketReached">Mantén el mínimo de inversión para permanecer en la lista de espera.</template>
                                 <template v-else>Te faltan: {{ explicitMoneyFormat(minInvestmentToSecondaryMarket) }}</template>
                              </p>
                              <vs-button v-if="!minInvestmentToSecondaryMarketReached" class="mt-5" color="dark" @click.stop="popUpPayFunds=true">Abonar</vs-button>
                           </div>
                        </div>

                        <div v-if="hasAutomaticInvestmentInactive" class="vx-row mt-base mb-base">
                           <div class="vx-col w-full">
                              <div class="flex flex-wrap items-center">
                                 <div class="flex flex-wrap items-center mb-2">
                                    <feather-icon icon="CheckCircleIcon" svgClasses="w-8 h-8 text-gray mr-2"/>
                                    <h3 class="bold mr-5">Activa la inversión automática</h3>                               
                                 </div>
                                 <!-- boton de inversion automatica -->
                                 <automatic-investment-button @automatic-investment-changed="automaticInvestmentChanged"/>
                              </div>
                              <p class="bold">Al activarla aceptas los <a class="inline-link" target="_blank" rel="noopener" @click="popUpAutomaticInvestmentTermsContract=true" > Términos y Condiciones de la inversión automática</a>.</p>
                           </div>
                        </div>

                        <div class="vx-row mt-base mb-base">
                           <div class="vx-col w-full">
                              <div class="flex flex-wrap items-center mb-2">
                                 <feather-icon v-if="InvestorRegisteredOnSecondaryMarket" icon="CheckCircleIcon" svgClasses="w-8 h-8 text-primary mr-2"/>
                                 <feather-icon v-else icon="CheckCircleIcon" svgClasses="w-8 h-8 text-gray mr-2"/>
                                 <h3 class="bold">Firma tu nuevo contrato</h3>
                              </div>
                              <p class="bold">Alcanza la inversión mínima y activa la inversión automática para poder firmar.</p>
                              <vs-button class="mt-3" v-if="!InvestorRegisteredOnSecondaryMarket" :disabled="!canSignSecondaryMarketContract" color="dark" @click.stop="showSecondaryMarketPopUp">Firmar</vs-button>
                           </div>
                        </div>
                     </div>
                  </vx-card>
               </div>
            </div>
         </div>

         <!-- PANEL DE CUMPLIMIENTO -->
         <compliance-banner @loadedRequirements="loadedRequirements" class="mb-base" />

         <!-- BANNER ABONA FONDOS A TU CUENTA -->
         <vx-card v-if="showInvestmentCard" class="mb-base mt-5" card-background="#d0eadc">
            <div class="vx-row">
               <div class="vx-col w-full">
                  <div slot="header" class="flex flex-wrap items-center flex-grow justify-between">
                     <img :src="figs.arrows" style="width: 50px" alt="" class="mr-5">
                     <div class="vx-row mt-3" style="display: unset;">
                        <div class="vx-col">
                           <h2 class="bold">¡Abona fondos a tu cuenta!</h2>
                        </div>
                        <div class="vx-col">
                           <label>Ya puedes abonar a tu cuenta de forma fácil y segura. Da clic en Abonar y te decimos
                              como.</label>
                        </div>
                     </div>
                     <vs-button class="ml-auto" color="dark" @click.stop="goToFunds">Abonar</vs-button>
                  </div>
               </div>
            </div>
         </vx-card>
         <!-- BANNER AUMENTA TU NIVEL -->
         <div class="mt-5" v-else-if="mainComplianceRequirements <= 0">
            <template v-if="isMounted">
               <investor-contract-level-banner :show-balance="false" />
            </template>
         </div>

         <div class="vx-row mt-base">
            <div class="vx-col w-full">
               <div class="flex items-end px-3">
                  <h2>Resumen de tus inversiones</h2>
               </div>
            </div>
         </div>
         <div class="vx-row">
         <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
               <vx-card class="h-auto text-center mt-5">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <img :src="figs.portfolio" style="width: 20%; display: unset;" alt="">
                        <h3 class="mt-5 mb-3">
                           {{ explicitMoneyFormat(totalPortfolio) }} MXN
                        </h3>
                        <label>
                           Valor del portafolio
                        </label>
                     </div>
                  </div>
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <vs-button class="mb-2" @click.stop="goToPortfolio">Ir al portafolio</vs-button>
                     </div>
                  </div>
               </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
               <vx-card class="h-auto text-center mt-5">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <img :src="figs.invest" style="width: 20%; display: unset;" alt="">
                        <h3 class="mt-5 mb-3">
                           {{ explicitMoneyFormat(availableToInvest) }} MXN
                        </h3>
                        <label>
                           Disponible para inversión
                        </label>
                     </div>
                  </div>
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <vs-button class="mb-2" @click.stop="goToInvestments">Invertir</vs-button>
                     </div>
                  </div>
               </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
               <vx-card class="h-auto text-center mt-5">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <img :src="figs.commitment" style="width: 20%; display: unset;" alt="">
                        <h3 class="mt-5 mb-3">
                           {{ explicitMoneyFormat(investmentCommitments) }} MXN
                        </h3>
                        <label>
                           Compromisos de inversión
                        </label>
                     </div>
                  </div>
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <vs-button class="mb-2" @click.stop="goToInvestorProjects">Ir a proyectos</vs-button>
                     </div>
                  </div>
               </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
               <vx-card class="h-auto text-center mt-5">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <img :src="figs.fixed" style="width: 20%; display: unset;" alt="">
                        <h3 class="mt-5 mb-3">
                           {{ explicitMoneyFormat(activeFixedInvestment) }} MXN
                        </h3>
                        <label>
                           Inversión fija
                        </label>
                     </div>
                  </div>
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <vs-button class="mb-2" @click.stop="goToInvestorProjects">Ver más</vs-button>
                     </div>
                  </div>
               </vx-card>
            </div>

         <!-- COL 1 -->
         <!-- <div class="vx-col xxl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 w-full">
          <notice-card
            v-if="showAutomaticInvestmentNoticeCard"
            variant-class="dark"
            button-text="Conocer Más"
            @on-button-action="goToPlans">
            <template v-slot:text>¡Invierte de forma <span>automática</span>!</template>
          </notice-card>

          <notice-card
            v-if="showSeedProjectsCard"
            variant-class="dark"
            button-text="¡Descúbrelo!"
            @on-button-action="goToSeedProjects">
            <template v-slot:text>¿Qué son los <span>proyectos semilla</span>?</template>
          </notice-card>

          <notice-card
            v-if="showInvestmentCard"
            variant-class="blue"
            button-text="Abonar fondos"
            button-id="add_funds_btn"
            @on-button-action="goToFunds">
            <template v-slot:text>¡Abona <span>fondos</span> a tu cuenta!</template>
          </notice-card> -->

         <!-- <notice-card
            v-if="showResaleProjectsCard"
            variant-class="clear"
            button-text="Descúbrelos"
            @on-button-action="goToResaleLanding">
            <template v-slot:text>Sistemas recuperados en <span>venta</span>.</template>
          </notice-card> -->

         <!-- <notice-card
            v-if="showImpactaMasCard"
            variant-class="green"
            button-text="¡Descubre cómo!"
            @on-button-action="goToImpactaMas">
            <template v-slot:text>¿Ya <span>subiste</span> de nivel?</template>
          </notice-card>

          <notice-card
            v-if="showRedferidosCard"
            variant-class="green"
            button-text="¡Invita a tus amigos!"
            @on-button-action="goToRedferidos">
            <template v-slot:text>¿A cuántos has <span>redferido</span>?</template>
          </notice-card>

          <informative-card variant-class="clear" v-if="isMounted">
            <template slot="title"> {{ moneyFormat(globalImpacts.financed) }} en energía limpia.</template>
            <template slot="content">
              Gracias a tu participación y la de otros {{ format_price(globalImpacts.investors) }} inversionistas, al día {{ midDateFormat(globalImpacts.date) }}
              logramos financiar {{ globalImpacts.projects }} proyectos semilla en {{ states }} estados de la república.
            </template>
            <template slot="actions">
              <vs-button type="border" class="border-2" @click="goToInvestments">¡Sigamos contribuyendo!</vs-button>
            </template>
          </informative-card>
        </div> -->
         <!-- END COL 1 -->

         <!-- COL 2 -->
         <!-- <div class="vx-col xxl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 w-full">
          <info-projects-card :projects="deliveredProjects" />

          <informative-card variant-class="clear" v-if="isMounted">
            <template slot="title">¡Evitamos {{ format_price(globalImpacts.emission_reduction) }} ton. de CO<sub>2</sub>!</template>
            <template slot="content">
              Juntos seguimos limpiando nuestro planeta al reducir las emisiones de dióxido de carbono. ¡No dejes de invertir con impacto!
            </template>
            <template slot="actions">
              <vs-button type="border" class="border-2" @click="goToImpactaMas">¡Impacta más!</vs-button>
            </template>
          </informative-card>
        </div> -->
         <!-- END COL 2 -->

         <!-- COL 3 -->
         <!-- <div class="vx-col xxl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 w-full">
          <investor-goals-card
            v-if="isMounted"
            :factors="factors"
            :investor-impacts="investorImpacts"
            :total-investor-invested="totalInvestorInvested" />
        </div> -->
         <!-- END COL 3 -->
         </div>

         <div class="vx-row mt-5">
            <div class="vx-col w-full">
               <div class="flex">
                  <feather-icon icon="InfoIcon" svgClasses="w-8 h-8 text-primary" class="mr-3" />
                  <span class="bold mt-1 italic">El valor de tu portafolio es la suma del saldo disponible para
                     inversión, los
                     compromisos de inversión y el monto de inversión fija.</span>
               </div>
            </div>
         </div>

         <!-- GRAFICA DE INVERSION FIJA Y RENDIMIENTOS -->
         <!-- <template v-if="showFixedInvestmentChart">
            <div class="vx-row mt-base">
               <div class="vx-col w-full">
                  <div class="flex items-end px-3">
                     <h2>Inversión fija y rendimientos</h2>
                  </div>
               </div>
            </div>

            <vx-card class="mb-base mt-5">
               <div class="vx-row">
                  <div class="vx-col xl:w-3/4 lg:w-3/4 md:w-full sm:w-full w-full mt-5">
                     <div class="vx-row mb-2">
                        <div class="vx-col w-full">
                           <vue-apex-charts type="line" height="400" :options="chartOptions"
                              :series="series"></vue-apex-charts>
                        </div>
                     </div>
                  </div>
                  <div class="vx-col xl:w-1/4 lg:w-1/4 md:w-full sm:w-full w-full mt-5">
                     <h3>¿Cómo calculamos tus rendimientos?</h3>
                     <div class="mt-5">
                        <p>
                           En esta gráfica te mostramos tu inversión fija y % de rendimiento mes con mes.
                           <br><br>El rendimiento se calcula de la siguiente manera.
                           <b>(Ganancias - Comisiones) / Inversión fija mes anterior x 12 (para anualizarlo)</b>. Las
                           Ganancias son la suma de los intereses ordinarios y moratorios,
                           así como cualquier bonificación adicional que hayas recibido.
                        </p>
                     </div>
                  </div>
               </div>
            </vx-card>
         </template> -->
      </div>

      <!-- INICIA INVITACION A MERCADO SECUNDARIO -->
      <!-- POP-UP SOBRE INVITACION AL MERCADO SECUNDARIO -->
      <vs-popup :active.sync="popUpSecondaryMarketInvitationActive" :title="popUpSecondaryMarketInvitationTitleDynamic" class="header-primary mid-popup">
         <div class="vx-row">
            <div class="vx-col w-full p-10">
                  <div v-if="popUpSecondaryMarketStep == 1">
                     <p>
                        Este nuevo contrato te permitiriá <b>diversificar</b> tu inversión y obtener <b>liquidez anticipada</b> (mercado secundario).
                        <br><br>
                        Estas son las nuevas comisiones:
                     </p>
                     <div class="vx-row">
                        <div class="vx-col w-full" style="height:300px; overflow-y:scroll">
                           <vs-table class="mt-base" noDataText="">
                              <template>
                                 <vs-tr>
                                    <vs-td class="bold">Comisión</vs-td>
                                    <vs-td class="bold">Antes</vs-td>
                                    <vs-td class="bold">Ahora</vs-td>
                                 </vs-tr>
                                 <vs-tr>
                                    <vs-td class="bold">Éxito</vs-td>
                                    <vs-td>2% del <b>total</b> pagado<br> (rendimiento + capital) </vs-td>
                                    <vs-td>10% solo del <b>rendimiento</b> pagado</vs-td>
                                 </vs-tr>
                                 <vs-tr>
                                    <vs-td class="bold">Transacción</vs-td>
                                    <vs-td>N/A</vs-td>
                                    <vs-td>2% del retiro por <b>liquidez <br> anticipada*</b></vs-td>
                                 </vs-tr>
                                 <vs-tr>
                                    <vs-td class="bold">Aclaración <br> improcedente</vs-td>
                                    <vs-td>N/A</vs-td>
                                    <vs-td>$500 por caso</vs-td>
                                 </vs-tr>
                                 <vs-tr>
                                    <vs-td class="bold">Contracargo <br> improcedente</vs-td>
                                    <vs-td>N/A</vs-td>
                                    <vs-td>$500 por caso</vs-td>
                                 </vs-tr>
                              </template>
                           </vs-table>
                           <small>
                              <p>
                                 *Solo aplica para liquidez obtenida en mercado secundario. Retirar tu saldo disponible no genera comisión.
                              </p>
                              <p class="mt-3">
                                 Los cambios en las comisiones aplicarán a partir de que firmes tu nuevo contrato.
                              </p>
                              <p class="mt-3">Todas las <a class="inline-link" href="https://www.redgirasol.com/comisiones" target="_blank" rel="noopener">comisiones</a> son más IVA.</p>
                              <p class="mt-3">El mercado secundario comenzará a operar en otoño de 2024.</p>
                           </small>
                        </div>
                     </div>
                     <div class="flex flex-wrap justify-center">
                        <vs-button class="mt-5" color="dark" @click.stop="popUpSecondaryMarketStep++">Continuar</vs-button>
                     </div>
                  </div>
                  <template v-if="popUpSecondaryMarketStep == 2">
                     <div class="vx-row">
                        <div class="vx-col w-full" style="height:400px; overflow-y:scroll">
                           <latest-investor-merchant-contract :fill-user-data="true"/>
                        </div>
                     </div>
                     <div class="flex flex-wrap justify-center">
                        <vs-button class="mt-base" color="dark" @click.stop="popUpSecondaryMarketStep++">Firmar</vs-button>
                     </div>
                  </template>
                  <template v-if="popUpSecondaryMarketStep == 3">
                     <div class="vx-row">
                        <div class="vx-col w-full">
                           <h5>Mercado secundario</h5>
                           <p class="mt-5">
                              Solicito a RedGirasol participar en el mercado secundario para diversificar mis inversiones y tener la posibilidad de obtener liquidez anticipada.
                           </p>
                           <div class="checkbox-display mt-5">
                              <vs-checkbox
                                 name="terms"
                                 v-model.lazy="acceptSecondaryMarketTerms">
                              </vs-checkbox>
                              <p>Entiendo y estoy de acuerdo con los <br>
                                 <a class="inline-link-regular" target="_blank" rel="noopener" @click="popUpSecondaryMarketTermsContract=true" >Términos y Condiciones del Mercado Secundario</a>.</p>
                           </div>
                        </div>
                     </div>
                     <div class="flex flex-wrap justify-center">
                        <vs-button class="mt-base" color="dark" :disabled="!acceptSecondaryMarketTerms" @click.stop="signSecondaryMarketContracts">Autorizar y continuar</vs-button>
                        
                     </div>
                     <p class="text-center mt-base">Apertura del mercado secundario: otoño 2024</p>
                  </template>
                  <template v-if="popUpSecondaryMarketStep == 4">
                     <div class="vx-row">
                        <div class="vx-col w-full">
                           <img src='https://cdn.redgirasol.com/investors/home/success_secondary_market_bnner.png' class="responsive">
                           <div class="text-center p-5">
                              <h3 class="mb-2">Pronto tu inversión contará con más liquidez y diversificación que nunca. Te notificaremos por correo cuando suceda el cambio.</h3>
                           </div>
                        </div>
                     </div>
                     <div class="flex flex-wrap justify-center">
                        <vs-button color="dark" @click.stop="popUpSecondaryMarketInvitationActive=false">Finalizar</vs-button>
                     </div>
                  </template>
            </div>
         </div>
         <!-- Popup para ver terminos y condiciones de mercado secundario -->
         <vs-popup :active.sync="popUpSecondaryMarketTermsContract" title="Términos y condiciones" class="extended-popup header-primary">
            <latest-secondary-market-terms-conditions-contract />
         </vs-popup>

         <!-- Popup para ver terminos y condiciones de inversion automatica -->
         <vs-popup :active.sync="popUpAutomaticInvestmentTermsContract" title="Términos y condiciones" class="extended-popup header-primary">
            <latest-automatic-investment-terms-conditions-contract />
         </vs-popup>

         <!-- Popup para ver datos para abonar fondos -->
         <vs-popup :active.sync="popUpPayFunds" title="Abona a tu cuenta" class="mid-popup header-primary">
            <stp-account-info class="p-10" :stpClabe="stpClabe" />
         </vs-popup>
      </vs-popup>
      
      <!-- Componente para obtener la geolocalización -->
      <rg-geolocator ref="rgGeolocator" />
      <!-- TERMINA INVITACION A MERCADO SECUNDARIO -->
   </div>
</template>

<script>

import ComplianceBanner from "@components/compliance/ComplianceBanner";
import NoticeCard from "@components/investor/widgets/NoticeCard";
import InformativeCard from "@components/investor/widgets/InformativeCard";
import InfoProjectsCard from "@components/investor/widgets/InfoProjectsCard";
import InvestorGoalsCard from "@components/investor/widgets/InvestorGoalsCard";
import formatHelper from "@mixins/formatHelper";
import stpHelper from "@mixins/stpHelper";
import dateHelper from "@mixins/dateHelper";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import FundReady from "@components/compliance/FundReady";
import { mapState } from "vuex";
import VueApexCharts from 'vue-apexcharts';
import InvestorContractLevelBanner from "@components/investor/plans/InvestorContractLevelBanner";
import FullImageCarousel from "@components/shared/FullImageCarousel";
import AutomaticInvestmentButton from "@components/investor/plans/AutomaticInvestmentButton";
import LatestInvestorMerchantContract from "@views/investor/contracts/LatestInvestorMerchantContract.vue";
import LatestSecondaryMarketTermsConditionsContract from "@views/investor/contracts/LatestSecondaryMarketTermsConditionsContract.vue";
import LatestAutomaticInvestmentTermsConditionsContract from "@views/investor/contracts/LatestAutomaticInvestmentTermsConditionsContract.vue";
import StpAccountInfo from "@components/stp/StpAccountInfo";

export default {
   components: {
      NoticeCard,
      InformativeCard,
      InfoProjectsCard,
      InvestorGoalsCard,
      ComplianceBanner,
      FundReady,
      VueApexCharts,
      InvestorContractLevelBanner,
      FullImageCarousel,
      AutomaticInvestmentButton,
      LatestInvestorMerchantContract,
      LatestSecondaryMarketTermsConditionsContract,
      LatestAutomaticInvestmentTermsConditionsContract,
      StpAccountInfo
   },
   mixins: [formatHelper, stpHelper, dateHelper, userIdentityHelper, investorInfoHelper],
   data() {
      return {
         isMounted: false,
         requirements: [],
         showPanel: null,
         showVerificationAccountCard: false,
         showAutomaticInvestmentNoticeCard: false,
         showResaleProjectsCard: false,
         showImpactaMasCard: false,
         showRedferidosCard: false,
         showInvestmentCard: false,
         showSeedProjectsCard: false,
         states: 29,
         showDemoCards: false,
         deliveredProjects: [],
         globalImpacts: {
            emission_reduction: 0,
            financed: 0,
            investors: 0,
            projects: 0,
            date: null,
         },
         factors: {
            ton_to_money_factor: 1,
            gain_to_investment_factor: 1,
         },
         totalInvestorInvested: 0,
         investorImpacts: {},
         identity: null,
         identityStatus: 0,
         funds: 0,
         hasAutomaticInvestmentInactive: false,
         stpClabe: null,
         user_person_type: null,
         availableToInvest: 0,
         investmentCommitments: 0,
         activeFixedInvestment: 0,
         totalPortfolio: 0,
         showFixedInvestmentChart: false,
         figs: {
            arrows: require('@assets/images/investor-figs/home-arrows-up.svg'),
            portfolio: require('@assets/images/investor-figs/home-portfolio.svg'),
            invest: require('@assets/images/investor-figs/home-invest.svg'),
            commitment: require('@assets/images/investor-figs/home-commitment.svg'),
            fixed: require('@assets/images/investor-figs/home-fixed-investment.svg'),
         },

         series: [{
            name: "",
            data: []
         }],
         chartOptions: {
            chart: {
               zoom: {
                  enabled: true
               },
               toolbar: {
                  tools: {
                     download: true,
                     zoom: true,
                     zoomin: true,
                     zoomout: true,
                     pan: true,
                     reset: true,
                  },
                  autoSelected: 'none'
               },
               locales: [{
                  "name": "en",
                  "options": {
                     "toolbar": {
                        "exportToPNG": "Descargar PNG",
                        "exportToCSV": "Descargar CSV",
                        "selectionZoom": "Zoom en área",
                        "zoomIn": "Acercar",
                        "zoomOut": "Alejar",
                        "pan": "Paneo",
                        "reset": "Reestablecer"
                     }
                  }
               }],
               defaultLocale: "en"
            },
            grid: {
               row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
               },
            },
            colors: ['#28de18', '#545454'],
            dataLabels: {
               enabled: false
            },
            xaxis: {
            },
            yaxis: [
               {
                  axisTicks: {
                     show: true,
                  },
                  axisBorder: {
                     show: true,
                     color: '#28de18'
                  },
                  labels: {
                     formatter: (value) => { return this.explicitMoneyFormat(value, 0) }
                  },
                  title: {
                     text: "Inversión fija",
                     style: {
                        color: '#28de18',
                     }
                  },
                  tooltip: {
                     enabled: true
                  }
               },
               {
                  seriesName: 'Income',
                  opposite: true,
                  axisTicks: {
                     show: true,
                  },
                  axisBorder: {
                     show: true,
                     color: '#545454'
                  },
                  labels: {
                     formatter: (value) => { return this.decimalPercentFormat(value, 2) }
                  },
                  title: {
                     text: "Rendimiento",
                     style: {
                        color: '#545454',
                     }
                  },
               },
            ],
            noData: {
               text: 'Cargando...'
            }
         },
         mainComplianceRequirements: 0,
         carouselImages: [
            {
               index: 0,
               url: "https://cdn.redgirasol.com/investors/home/secondary_market_slide_1.png",
               filename: ""
            },
            {
               index: 1,
               url: "https://cdn.redgirasol.com/investors/home/secondary_market_slide_2.png",
               filename: ""
            },
            {
               index: 2,
               url: "https://cdn.redgirasol.com/investors/home/secondary_market_slide_3.png",
               filename: ""
            },
            {
               index: 3,
               url: "https://cdn.redgirasol.com/investors/home/secondary_market_slide_4.png",
               filename: ""
            },
            {
               index: 4,
               url: "https://cdn.redgirasol.com/investors/home/secondary_market_slide_5.png",
               filename: ""
            },
         ],
         popUpSecondaryMarketInvitationTitle: "",
         popUpSecondaryMarketInvitationActive: false,
         popUpSecondaryMarketStep:1,
         acceptSecondaryMarketTerms:false,
         popUpSecondaryMarketTermsContract:false,
         popUpAutomaticInvestmentTermsContract:false,
         popUpPayFunds:false,
         contractSecondaryMarketType:26,
      }
   },
   watch: {
      popUpSecondaryMarketInvitationActive: function (newv, oldv){
         if(newv === false){
            this.popUpSecondaryMarketStep = 1;
         }
      }
   },
   computed: {
      ...mapState("auth", ["roleGeneralData"]),
      isMexicanPM() {
         return this.user_person_type === 0;
      },
      isMexicanPF() {
         return this.user_person_type === 1;
      },
      isSmallerScreen() {
         return this.$store.state.windowWidth <= 576;
      },
      popUpSecondaryMarketInvitationTitleDynamic() {
         let title = "";
         switch (this.popUpSecondaryMarketStep) {
            case 0:
            case 1:
            case 2:
               title = "Tu nuevo contrato";
               break;
            case 3:
               title = "Consentimiento";
               break;
            default:
               break;
         }
         return title;
      },
      minInvestmentToSecondaryMarket(){
         return 5000-this.totalPortfolio;
      },
      minInvestmentToSecondaryMarketReached(){
         return this.minInvestmentToSecondaryMarket <= 0;
      },
      secondaryMarketStepsCount(){
         let total = 0;
         if (this.minInvestmentToSecondaryMarketReached) {
            total++;
         }
         if (!this.hasAutomaticInvestmentInactive) {
            total++;
         }
         if (this.InvestorRegisteredOnSecondaryMarket) {
            total++;
         }
         return total;
      },
      canSignSecondaryMarketContract(){
         return this.minInvestmentToSecondaryMarketReached && !this.hasAutomaticInvestmentInactive;
      },
   },
   async beforeMount() {
      this.user_person_type = parseInt(this.UserPersonType);
   },
   async mounted() {
      this.isMounted = false;
      await this.showLoading(true)
      await this.onUserInfoUpdated();
      await this.getDataForHome();
      await this.getPlansInfo();
      await this.checkForStpInfo();
      await this.setCardsSettings();
      this.isMounted = true
      await this.showLoading(false)
   },
   methods: {
      async getDataForHome() {
         try {
            let response = await axios.get(`api/v2/investor/${this.InvestorId}/getDataForHomePage`);

            // DATOS PARA VERSION ANTERIOR DE HOME
            // this.deliveredProjects = response.data.deliveredProjects;
            // if (response.data.globalImpacts !== null) {
            //    this.globalImpacts = response.data.globalImpacts;
            // }
            // this.identity = response.data.identity;
            // this.factors = response.data.factors;
            // this.totalInvestorInvested = response.data.totalInvested;
            // this.investorImpacts = response.data.investorImpacts;
            // this.funds = response.data.funds;

            // DATOS PARA VERSION ACTUAL DE HOME
            this.identity = response.data.identity;
            this.availableToInvest = response.data.availableToInvest;
            this.investmentCommitments = response.data.investmentCommitments;
            this.activeFixedInvestment = response.data.activeFixedInvestment;
            this.totalPortfolio = response.data.totalPortfolio;
            let seriesPercentageData = [];
            let seriesData = [];
            let seriesLabels = [];
            response.data.chartData.forEach(f => {
               seriesData.push(f.fixed_investment);
               seriesLabels.push(f.balance_date);
               seriesPercentageData.push(f.total);
            });
            this.series = [
               {
                  name: "Inversión fija (MXN)",
                  data: seriesData
               },
               {
                  name: "Rendimiento",
                  data: seriesPercentageData
               },
            ];
            this.chartOptions.xaxis = {
               categories: seriesLabels
            };

            // SI LOS DATOS PARA LA GRAFICA DE INVERSION FIJA TIENE MAS DE UN MES SE MUESTRA
            if (response.data.chartData.length > 1) {
               this.showFixedInvestmentChart = true;
            }

            // SE VALIDAN DATOS PARA MOSTRAR O NO EL BANNER DE LA VERIFICACION DE IDENTIDAD
            if (this.identity) {
               this.identityStatus = this.identity.identity_status;
            }
         }
         catch (e) {
            this.warn(e);
         }
      },
      async getPlansInfo() {
         try {
            const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`);
            const investorPlan = res.data.plan;
            this.hasAutomaticInvestmentInactive = investorPlan.autoinvest_applicant_payments === 0;
         }
         catch (e) {
         }
      },
      async checkForStpInfo() {
         // solicitar informacion de stp solo si corresponde
         // panel completo y persona fisica mexicana
         if (this.isMexicanPF || this.isMexicanPM) {
            this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
         }
      },
      async setCardsSettings() {
         // tarjeta: abonar fondos
         // Aparece cuando su saldo es igual a 0 y tiene clabe stp
         if (this.roleGeneralData.balance === 0 && this.stpClabe !== null) {
            this.showInvestmentCard = true;
            // this.$router.replace({name: 'abonarFondos'});
            return;
         }
         // tarjeta: proyectos semilla
         // Aparece cuando su estatus es menor a 3 o no tiene clabe stp
         if (this.InvestorStatus < 3 || this.stpClabe === null) {
            this.showSeedProjectsCard = true;
            return;
         }
         // tarjeta: proyectos semilla
         // Aparece cuando su saldo es mayor a 0 e investor status es menor a 3
         if (this.roleGeneralData.balance > 0 && this.InvestorStatus < 3) {
            this.showSeedProjectsCard = true;
            return;
         }
         if (this.InvestorStatus > 2) {
            // tarjeta: automatiza tus inversiones
            // Aparece cuando investor status es mayor a 2 y tiene deshabilitada su inversion automatica
            if (this.hasAutomaticInvestmentInactive) {
               this.showAutomaticInvestmentNoticeCard = true;
               return;
            }

            // si existen proyectos en reventa se hace un random para las cards
            if (this.ResaleProjects > 0) {
               var option = this.getRandomIntInclusive(1, 3);
               this.showImpactaMasCard = false;
               this.showRedferidosCard = false;
               this.showResaleProjectsCard = false;
               switch (option) {
                  case 1:
                     this.showImpactaMasCard = true;
                     break;
                  case 2:
                     this.showRedferidosCard = true;
                     break;
                  // case 3:
                  //   this.showResaleProjectsCard = true;              
                  //   break;
               }
            }
            // si no se sigue con la logica normal de dias pares y nones
            else {
               // tarjetas por dias
               let day = new Date().getDate();
               let isEven = (day % 2 === 0);
               // tarjeta: subiste de nivel
               //Aparece los días pares
               this.showImpactaMasCard = isEven;
               // tarjeta: redferidos
               //Aparece los días nones
               this.showRedferidosCard = !isEven;
            }
         }
      },
      async goToProfile() {
         await this.$router.push({ name: 'perfilInversionista' });
      },
      async goToAboutYou() {
         await this.$router.push({ name: 'perfilInversionistaAbout' });
      },
      async goToSeedProjects() {
         window.open("https://blog.redgirasol.com/como-funcionan-los-proyectos-semilla/", "_blank");
      },
      async goToPortfolio() {
         await this.$router.push({ name: 'miPortafolio' });
      },
      async goToImpactaMas() {
         await this.$router.push({ name: 'impactaMasInversionista' });
      },
      async goToRedferidos() {
         await this.$router.push({ name: 'redferidos' });
      },
      async goToInvestments() {
         await this.$router.push({ name: 'oportunidades' });
      },
      async goToFunds() {
         await this.$router.push({ name: 'abonarFondos' });
      },
      async goToPlans() {
         await this.$router.push({ name: 'planesInversion' });
      },
      async goToResaleLanding() {
         await this.$router.push({ name: 'recoveredProjects' });
      },
      async goToInvestorProjects() {
         await this.$router.push({ name: 'misProyectosInversionista' });
      },
      getRandomIntInclusive(min, max) {
         const randomBuffer = new Uint32Array(1);
         window.crypto.getRandomValues(randomBuffer);
         let randomNumber = randomBuffer[0] / (0xffffffff + 1);
         min = Math.ceil(min);
         max = Math.floor(max);
         return Math.floor(randomNumber * (max - min + 1)) + min;
      },
      loadedRequirements(count) {
         this.mainComplianceRequirements = count;
      },
      showSecondaryMarketPopUp(){
         // se pide la geolocalizacion para la operacion
         this.$refs.rgGeolocator.askForGeolocation();
         this.popUpSecondaryMarketInvitationActive = true;
      },
      async automaticInvestmentChanged(value){
         this.hasAutomaticInvestmentInactive = !value;
      },
      async signSecondaryMarketContracts(){
         this.showLoading(true, "Guardando información...");
         try {
            const payload = { geolocation: this.CurrentGeolocationData}
            await axios.post(`/api/v2/investor/${this.InvestorId}/sign-secondary-market-contracts`, payload);
            this.isMounted = false;
            await this.onUserInfoUpdated();
            await this.getDataForHome();
            await this.getPlansInfo();
            await this.checkForStpInfo();
            await this.setCardsSettings();
            this.isMounted = true;
            this.popUpSecondaryMarketStep = 4;
            this.showLoading(false);            
         } catch (e) {
            this.showLoading(false);
            this.errorNotifDialog("Error en la operación", e);
         }
      }
   }
};
</script>

<style>
.big-label {
   font-size: 24px;
}
.big-label-sub {
   font-size: 20px;
}
.checkbox-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
</style>
