<template>
  <vx-card class="p-6 mt-6" :card-background="currentBgColor" :content-color="currentContentColor">
    <template slot="no-body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="text-xl bold"><slot name="title"></slot></p>
        </div>
      </div>
      <div class="vx-row mt-3">
        <div class="vx-col w-full">
          <p class="text-sm"><slot name="content"></slot></p>
        </div>
      </div>
      <div class="vx-row mt-5">
        <div class="vx-col w-full">
          <slot name="actions"></slot>
        </div>
      </div>

    </template>
  </vx-card>
</template>

<script>
export default{
  name: 'InformativeCard',
  props: {
    variantClass: {
      type: String,
      required: true,
      default: 'clear',
      validator: function (value) {
        // The value must match one of these strings
        return ['clear', 'dark'].indexOf(value) !== -1
      }
    },
  },
  data() {
    return {
      currentBgColor: null,
      currentContentColor: null,
    }
  },
  beforeMount() {
    this.autoSet();
  },
  methods: {
    autoSet(){
      switch (this.variantClass){
        case 'dark':
          this.currentBgColor = 'dark';
          this.currentContentColor = 'white'
          break;
        case 'clear':
          this.currentBgColor = '';
          this.currentContentColor = '';
          break;
      }
    },
  }
}
</script>

<style>
.notice-text {
  font-family: 'gilroyextrabold', sans-serif;
  text-align: center;
}
.notice-text.primary,
.notice-text.green {
  color: #3b3a3e !important;
}

.notice-text.clear {
  color: rgba(var(--vs-dark), 1) !important;
}

.notice-button-container {;
  text-align: center;
}

.notice-text.dark > span {
  color: rgba(var(--vs-primary), 1) !important;
}

.investor-notice {
  background-size: cover;
  background-repeat: repeat;
}
</style>