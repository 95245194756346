<template>
  <vx-card
    :class="variantClass == 'blue' ? 'investor-notice_new_initial': 'investor-notice_new'"
    class="lg:py-32 md:py-24 sm:py-12 py-6 mt-3"
    :style="{ backgroundImage: currentBg, backgroundColor: currentColor}" >
    <div class="notice-text xxl:text-5xl lg:text-4xl md:text-3xl sm:text-3xl text-3xl leading-min" :class="variantClass">
      <slot name="text"></slot>
    </div>
    <div class="notice-button-container mt-6">
      <vs-button :id="buttonId" class="notice-button" :color="currentBtnColor" @click="executeAction">{{buttonText}}</vs-button>
    </div>
  </vx-card>
</template>

<script>
export default{
  name: 'NoticeCard',
  props: {
    variantClass: {
      type: String,
      required: true,
      default: 'primary',
      validator: function (value) {
        // The value must match one of these strings
        return ['primary', 'dark', 'clear', 'green', 'blue'].indexOf(value) !== -1
      }
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonId: {
      type: String,
      required: false,
      default: null
    },
  },
  data() {
    return {
      primaryBg: `url(${require('@assets/images/pages/makeover_green/GRAFICOS-08.png')})`,
      whiteBg: `url(${require('@assets/images/pages/makeover_green/GRAFICOS-07.png')})`,
      currentBg: null,
      currentBtnColor: null,
      currentColor: null,
    }
  },
  beforeMount() {
    this.autoSet();
  },
  methods: {
    autoSet(){
      switch (this.variantClass){
        case 'primary':
          this.currentColor = "#FFF";
          this.currentBtnColor = 'dark';
          this.currentBg = this.primaryBg;
          break;
        case 'dark':
          this.currentColor = '#3a393d';
          this.currentBtnColor = 'primary';
          this.currentBg = this.primaryBg;
          break;
        case 'clear':
          this.currentColor = "#d1d3d4";
          this.currentBtnColor = 'dark';
          this.currentBg = this.whiteBg;
          break;
        case 'green':
          this.currentColor = '#28DE18';
          this.currentBtnColor = 'dark';
           this.currentBg = this.whiteBg;
          break;
        case 'blue':
          this.currentColor = "#28DE18";
          this.currentBtnColor = 'dark';
          this.currentBg = this.whiteBg +",linear-gradient(to right, rgba(203,255,0,1) 70%, rgba(40,222,24,1) 100%)";
          break;
      }
    },
    async executeAction(){
      await this.$emit('on-button-action');
    }
  }
}
</script>

<style>
.notice-text {
  font-family: 'gilroyextrabold', sans-serif;
  text-align: center;
}
.notice-text.primary,
.notice-text.green,
.notice-text.blue {
  color: #3b3a3e !important;
}

.notice-text.dark {
  color: white !important;
}

.notice-text.clear {
  color: rgba(var(--vs-dark), 1) !important;
}

.notice-button-container {;
  text-align: center;
}


.notice-text.blue > span,
.notice-text.green > span,
.notice-text.clear > span {
  color: white !important;
}
.notice-text.dark > span,
.notice-text.primary > span {
  color: rgba(var(--vs-primary), 1) !important;
}

.investor-notice_new {
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position-x: -38rem !important;
  background-size: 56rem !important;
  background-position-y: -10rem !important;
}

.investor-notice_new_initial {
  /* background-size: cover; */
  background-repeat: repeat-y;
  background-position-x: -38rem !important;
  background-size: 56rem !important;
  background-position-y: -10rem !important;
}
</style>