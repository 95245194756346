<!-- =========================================================================================
    ACTUALIZAR LAS VARIABLES COMPUTADAS EN DONDE SEA NECESARIO PARA LLENAR LOS DATOS CORRESPONDIENTES DEL USUARIO
    ASI COMO TAMBIEN LA FECHA ACTUAL DE FIRMA DEL CONTRATO
========================================================================================== -->
<template>
    <div>
        <div class="container-fluid">
            <div class="col-md-12 p-20">
                <h1
                    style="font-weight:bold; margin-bottom:6pt; text-align:center; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <span style="">T&eacute;rminos y Condiciones de la Inversi&oacute;n
                        Autom&aacute;tica</span></h1>
                <p style="margin-top:0pt; margin-bottom:0pt;">&nbsp;</p>
                <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-36pt; text-align:center;">
                    <span style="">Fecha de actualizaci&oacute;n: 19 de agosto de
                        2024</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">RedGirasol ha celebrado contrato(s) de comisi&oacute;n
                        mercantil con cada Inversionista (personas f&iacute;sicas o morales), registrado en el Sitio,
                        con el objetivo de financiar colectivamente Proyectos de los Solicitantes. Los presentes
                        T&eacute;rminos y Condiciones establecen los objetivos y mecanismos por el cual cada
                        Inversionista opta por participar en la Inversi&oacute;n Autom&aacute;tica
                        (&ldquo;</span><u><span style="">Terminos y
                            Condiciones</span></u><span style="">&rdquo;).</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <ol type="1" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt;  font-weight:bold;">
                        Definiciones<span style="font-weight:normal;">. Para efectos de estos T&eacute;rminos y
                            Condiciones, los siguientes t&eacute;rminos tendr&aacute;n las siguientes
                            definiciones.</span></li>
                </ol>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style="">&nbsp;</span></strong></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify; "><strong><span
                            style=" font-weight:bold;">CUITF:&nbsp;</span></strong><span
                        style="">Significan las Disposiciones de Car&aacute;cter General
                        aplicables a las Instituciones de Tecnolog&iacute;a Financiera.</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style="">&nbsp;</span></strong></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style="font-weight:bold;">Inversionista</span></strong><span
                        style="">&nbsp;o en conjunto&nbsp;</span><strong><span
                            style=" font-weight:bold;">Inversionistas</span></strong><span
                        style="">: Las personas f&iacute;sicas o morales, que, registradas
                        en el Sitio, aportan recursos a los Solicitantes para financiar colectivamente los
                        Proyectos.</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">Inversi&oacute;n
                            Autom&aacute;tica</span></strong><span style="">:
                        Instrucci&oacute;n que el Inversionista da a RedGirasol en el Sitio, para que invierta de manera
                        autom&aacute;tica su saldo disponible en el financiamiento del Mercado Primario y, en su caso,
                        del Mercado Secundario, de acuerdo con lo establecido en el presente documento. El Inversionista
                        reconoce que el saldo disponible que provenga de saldos y/o dep&oacute;sitos no se encuentra
                        previsto en el Art&iacute;culo 54 de la CUITF.</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">Inversi&oacute;n Efectiva:</span></strong><span
                        style="">&nbsp;La suma del saldo insoluto de todos los Proyectos
                        que sea acreedor un Inversionista, en t&eacute;rminos del Art&iacute;culo 49 de la CUITF.</span>
                </p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">Fila de Liquidez:</span></strong><span
                        style="">&nbsp;La jerarqu&iacute;a para ejecutar las
                        &oacute;rdenes de venta del Mercado Secundario de acuerdo al principio de primeras entradas,
                        primeras salidas.</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">Mercado Primario:&nbsp;</span></strong><span
                        style="">Es el medio por el cual los Inversionistas aportan los
                        fondos a los Proyectos.</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style="">&nbsp;</span></strong></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">Mercado Secundario:&nbsp;</span></strong><span
                        style="">Es el medio por el cual los Inversionistas pueden
                        permutar, vender o comprar los derechos o t&iacute;tulos intercambiados que documenten la
                        Inversi&oacute;n Efectiva con otros Inversionistas, con el objetivo de diversificar sus
                        portafolios y, consecuentemente, disminuir sus riesgos y obtener, eventualmente, liquidez de su
                        Inversi&oacute;n Efectiva.&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">Proyectos</span></strong><span
                        style="">: La operaci&oacute;n de financiamiento colectivo que
                        tiene por objeto que los Inversionistas otorguen un pr&eacute;stamo o cr&eacute;dito a los
                        Solicitantes.</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">RedGirasol</span></strong><span
                        style="">: Red Girasol, S.A.P.I. de C.V., Instituci&oacute;n de
                        Financiamiento Colectivo</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">Sitio</span></strong><span
                        style="">: significa el sitio de internet&nbsp;</span><a
                        href="https://www.redgirasol.com/" style="text-decoration:none;"><u><span
                                style=" color:#0000ff;">https://www.redgirasol.com/</span></u></a>
                </p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style=" font-weight:bold;">Solicitantes</span></strong><span
                        style="">: aquellas personas, f&iacute;sicas o morales, que
                        registradas en el Sitio como tal, hubiesen requerido y obtenido recursos de los Inversionistas
                        para financiar los Proyectos.</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt;"><strong><span
                            style="">&nbsp;</span></strong></p>
                <p style="margin-top:0pt; margin-bottom:0pt;"><strong><span
                            style=" font-weight:bold;">Objetivos de la Inversi&oacute;n
                            Autom&aacute;tica.&nbsp;</span></strong><span style="">La
                        Inversi&oacute;n Autom&aacute;tica tiene los siguientes objetivos:</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt;"><span style="">&nbsp;</span>
                </p>
                <ol type="a" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:48.63pt; text-align:justify; padding-left:5.37pt; ">
                        Que los Inversionistas puedan maximizar el aprovechamiento de su capital.</li>
                </ol>
                <p style="margin-top:0pt; margin-left:54pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <ol start="2" type="a" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:49.25pt; text-align:justify; padding-left:4.75pt; ">
                        Que los Inversionistas puedan diversificar<strong>&nbsp;</strong>su Inversi&oacute;n Efectiva en
                        los Proyectos, reduciendo el riesgo y consecuentemente, la volatilidad.</li>
                </ol>
                <p style="margin-top:0pt; margin-left:54pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <ol start="3" type="a" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:48.63pt; text-align:justify; padding-left:5.37pt; ">
                        Que los Inversionistas puedan automatizar sus operaciones.</li>
                </ol>
                <h1
                    style="margin-top:20pt; margin-bottom:6pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <a name="_f1p4nhcivxry"></a><a name="_xdnidw4i82ef"></a><a name="_9pwhqt8bibcj"></a><a
                        name="_q1fml3g21mu6"></a><span style=" font-weight:bold;">2. Reglas Generales</span>
                </h1>
                <h2
                    style="margin-top:18pt; margin-bottom:6pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <a name="_y1qsbc31nm8"></a><span style="">2.1.</span><span
                        style="width:19.5pt;  font-weight:normal; display:inline-block;">&nbsp;</span><span
                        style=" font-weight:bold;">Participaci&oacute;n</span><span
                        style=" font-weight:normal;">.&nbsp;</span></h2>
                <h2
                    style="margin-top:18pt; margin-bottom:6pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <span style=" font-weight:normal;">El Inversionista podr&aacute;
                        instruir a RedGirasol, por medio de consentimiento expreso, su deseo de participar en la
                        Inversi&oacute;n Autom&aacute;tica, lo cual le permitir&aacute;: (i) maximizar el
                        aprovechamiento de su capital (ii) diversificar su Inversi&oacute;n Efectiva; y, (iii)
                        automatizar sus operaciones.</span></h2>
                <h2
                    style="margin-top:18pt; margin-bottom:6pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <a name="_rjphpglvryxg"></a><span style="">2.2</span><span
                        style="width:22.25pt;  display:inline-block;">&nbsp;</span><span
                        style=" font-weight:bold;">Aprovechamiento del capital.</span></h2>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">El Inversionista con el objetivo de maximizar el
                        aprovechamiento de su capital, ordena a RedGirasol utilizar la totalidad de su saldo disponible
                        para la Inversi&oacute;n Autom&aacute;tica, es decir, los recursos que provengan de:</span></p>
                <ol type="a" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:30.63pt; text-align:justify; padding-left:5.37pt; ">
                        Abonos o dep&oacute;sitos. Los recursos que el Inversionista abone o deposite a su cuenta.</li>
                    <li
                        style="margin-left:31.25pt; text-align:justify; padding-left:4.75pt; ">
                        Pagos efectuados. Los recursos que el Inversionista reciba derivado de su Inversi&oacute;n
                        Efectiva.</li>
                    <li
                        style="margin-left:30.63pt; text-align:justify; padding-left:5.37pt; ">
                        Operaciones no perfeccionadas. Los recursos que el Inversionista reciba derivado de la
                        cancelaci&oacute;n o reembolso de un Proyecto.</li>
                </ol>
                <h2
                    style="margin-top:18pt; margin-bottom:6pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <a name="_egeywpu9k3f2"></a><a name="_8tvylwkomcss"></a><a name="_ky3o9frvm04g"></a><a
                        name="_84urlwigath5"></a><span style="">2.3</span><span
                        style="width:22.25pt;  display:inline-block;">&nbsp;</span><span
                        style=" font-weight:bold;">Asignaci&oacute;n de capital para Inversionistas que no
                        participen en el Mercado Secundario.&nbsp;</span><span
                        style=" font-weight:normal;">En caso de que el Inversionista no
                        participe en el Mercado Secundario, el 100% del saldo disponible para invertir, se
                        destinar&aacute; para el financiamiento del Mercado Primario.</span></h2>
                <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">&nbsp;</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><strong><span
                            style="">2.4</span></strong><strong><span
                            style="width:22.25pt;  display:inline-block;">&nbsp;</span></strong><strong><span
                            style=" font-weight:bold;">Asignaci&oacute;n de capital para Inversionistas que
                            participen en el Mercado Secundario.</span></strong></p>
                <ol type="a" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:66.63pt; text-align:justify; padding-left:5.37pt; ">
                        En caso de que el inversionista participe en el Mercado Secundario, el 50% del saldo disponible
                        para invertir, se destinar&aacute; para el financiamiento del Mercado Primario.</li>
                    <li
                        style="margin-left:67.25pt; text-align:justify; padding-left:4.75pt; ">
                        El saldo disponible restante se destinar&aacute; para el financiamiento del Mercado Secundario,
                        siguiendo el orden de la Fila de Liquidez.</li>
                    <li
                        style="margin-left:66.63pt; text-align:justify; padding-left:5.37pt; ">
                        En caso de que no exista requerimiento de financiamiento en el Mercado Primario, el saldo
                        disponible se destinar&aacute; al financiamiento en el Mercado Secundario, y viceversa.</li>
                    <li
                        style="margin-left:67.25pt; text-align:justify; padding-left:4.75pt; ">
                        En caso de que no exista requerimiento de financiamiento en el Mercado Primario ni en el Mercado
                        Secundario, con el objetivo de maximizar la liquidez, autom&aacute;ticamente se generar&aacute;n
                        &oacute;rdenes de venta, de manera proporcional a la participaci&oacute;n de cada Inversionista,
                        para que todos los Inversionistas que participan en el Mercado Secundario tengan la misma
                        proporci&oacute;n de Inversi&oacute;n Efectiva y saldo disponible.</li>
                </ol>
                <h3
                    style="margin-top:16pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <span style=" color:#434343;">2. 5&nbsp;</span><span
                        style=" font-weight:bold;">Ejecuci&oacute;n de la Inversi&oacute;n
                        Autom&aacute;tica.&nbsp;</span><span
                        style=" font-weight:normal;">La Inversi&oacute;n Autom&aacute;tica
                        se activar&aacute; en el momento que establezca RedGirasol.</span></h3>
                <h3
                    style="margin-top:16pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <span style=" color:#434343;">2.6</span><span
                        style="width:22.25pt;  display:inline-block;">&nbsp;</span><span
                        style=" color:#434343; font-weight:bold;">Forma y t&eacute;rminos en que se
                        llevar&iacute;an a cabo las Inversiones Autom&aacute;ticas</span></h3>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">a. Mercado Primario</span></p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">El algoritmo que distribuye el saldo disponible para el
                        financiamiento del Mercado Primario tiene por objetivo maximizar la diversificaci&oacute;n y
                        liquidez. El algoritmo colocar&aacute; el saldo disponible de acuerdo con los siguientes
                        principios:</span></p>
                <ol type="i" style="margin:0pt; padding-left:0pt;">
                    <li style="margin-left:95pt; text-align:justify; padding-left:13pt; ">
                        Distribuir el saldo disponible en la mayor cantidad de Proyectos, los cuales para ser publicados
                        fueron filtrados, verificados y calificados por el equipo de riesgo crediticio de RedGirasol.
                    </li>
                    <li style="margin-left:95pt; text-align:justify; padding-left:13pt; ">
                        Cumplir con los niveles de concentraci&oacute;n establecidos en el Art&iacute;culo 49 de la
                        CUITF.</li>
                </ol>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">Diversificar de acuerdo con el capital del inversionista,
                        no del tama&ntilde;o de los Proyectos</span></p>
                <ol start="2" type="a" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:31.25pt; text-align:justify; padding-left:4.75pt; ">
                        Mercado Secundario</li>
                </ol>
                <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify;"><span
                        style="">El algoritmo que distribuye el saldo disponible para el
                        financiamiento del Mercado Secundario colocar&aacute; el saldo disponible respetando la Fila de
                        Liquidez.</span></p>
                <h3
                    style="margin-top:16pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <span style=" color:#434343;">2.7</span><span
                        style="width:22.25pt;  display:inline-block;">&nbsp;</span><span
                        style=" color:#434343; font-weight:bold;">Criterios de selecci&oacute;n y nivel de
                        Riesgo</span></h3>
                <ol type="a" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:66.63pt; text-align:justify; padding-left:5.37pt; ">
                        Debido a que el algoritmo tiene por objetivo maximizar la diversificaci&oacute;n, esto implica
                        que el Inversionista tenga una exposici&oacute;n de riesgo a cualquier Proyecto autorizado por
                        RedGirasol.</li>
                    <li
                        style="margin-left:67.25pt; text-align:justify; padding-left:4.75pt; ">
                        Los criterios a utilizar para seleccionar a los Solicitantes o Proyectos sobre los que se
                        invertir&aacute;n los recursos, as&iacute; como el nivel de riesgo al cual estar&aacute;n
                        expuestas las inversiones se pueden consultar en el Sitio.</li>
                </ol>
                <h3
                    style="margin-top:16pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-size:11pt;">
                    <span style=" color:#434343;">2.8</span><span
                        style="width:22.25pt;  display:inline-block;">&nbsp;</span><span
                        style=" color:#434343; font-weight:bold;">Abono recurrente</span></h3>
                <ol type="a" style="margin:0pt; padding-left:0pt;">
                    <li
                        style="margin-left:66.63pt; text-align:justify; padding-left:5.37pt; ">
                        El Inversionista podr&aacute; instruir a RedGirasol para domiciliar un cargo recurrente,
                        definiendo el banco, monto y d&iacute;a de cobro.</li>
                    <li
                        style="margin-left:67.25pt; text-align:justify; padding-left:4.75pt; ">
                        En caso de que el cargo no sea exitoso, RedGirasol realizar&aacute; intentos adicionales.</li>
                    <li
                        style="margin-left:66.63pt; text-align:justify; padding-left:5.37pt; ">
                        En caso de no lograr realizar un cargo exitoso en tres meses consecutivos, se suspender&aacute;
                        el servicio.</li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import dateHelper from '@mixins/dateHelper';
import stpHelper from "@mixins/stpHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";

export default {
    name: "LatestAutomaticInvestmentTermsConditionsContract",
    mixins: [dateHelper, stpHelper, investorInfoHelper],
    props: {
        fillUserData: {
            type: Boolean
            , default: false
        },
        signedDate: { required: false, default: null },
        onlyCoverPage: { required: false, default: false }
    },
    data() {
        return {}
    },
    computed: {
        userName: function () {
            if (this.fillUserData && this.user) {
                return this.user.complete_name;
            } else {
                return "";
            }
        },
        userEmail: function () {
            if (this.fillUserData) {
                return (this.user === null) ? "-" : this.user.email;
            } else {
                return "";
            }
        },
        currentDate: function () {
            if (this.signedDate) {
                return this.signedDate;
            } else {
                return this.signatureFormatDate(new Date());
            }
        },
    },
}
</script>

<style scoped>
ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol>li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol>li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol>li {
    margin: 0;
}

li ol>li:before {
    content: counters(item, ".") " ";
}

.parent {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 14px;
}

.parent>div {
    border: solid .5px #d2d2d2;
    padding-left: 2rem;
}

.list-disc {
    list-style-type: disc !important;
    margin-left: 3rem !important;
}

.div1 {
    grid-area: 3 / 1 / 4 / 3;
    text-align: center;
}

.div2 {
    grid-area: 3 / 3 / 4 / 5;
    text-align: center;
}

.div3 {
    grid-area: 3 / 5 / 4 / 7;
    text-align: center;
}

.div4 {
    grid-area: 1 / 1 / 2 / 7;
}

.div5 {
    grid-area: 2 / 1 / 3 / 7;
}

.div6 {
    grid-area: 4 / 1 / 5 / 3;
}

.div7 {
    grid-area: 4 / 3 / 5 / 5;
}

.div8 {
    grid-area: 4 / 5 / 5 / 7;
}

.div9 {
    grid-area: 5 / 1 / 6 / 7;
    text-align: center;
}

.div10 {
    grid-area: 6 / 1 / 7 / 4;
}

.div11 {
    grid-area: 6 / 4 / 7 / 7;
}

.div12 {
    grid-area: 7 / 1 / 9 / 7;
}

.div13 {
    grid-area: 9 / 1 / 10 / 7;
    text-align: center;
}

.div14 {
    grid-area: 10 / 1 / 11 / 3;
}

.div15 {
    grid-area: 10 / 3 / 11 / 5;
}

.div16 {
    grid-area: 10 / 5 / 11 / 7;
}

.div17 {
    grid-area: 11 / 1 / 12 / 7;
}

.div18 {
    grid-area: 12 / 1 / 13 / 7;
}

.div19 {
    grid-area: 13 / 1 / 14 / 7;
}
</style>