<template>
  <div v-if="isMounted">
    <vs-switch color="success" v-model="autoInvestPayments"
    @click.stop="confirmSwitchInvestmentPlanOption('autoinvest_applicant_payments', !autoInvestPayments)" />
  </div>
</template>

<script>
export default {
  name: "AutomaticInvestmentButton",
  mixins: [],
  data() {
    return {
      isMounted: false,
      autoInvestPayments: false,
      investorPlan: {
        id: null,
        card_id: null,
        card_amount: null,
        reinvestment_percentage: null,
        qualifications: null,
        monthly_day: null,
        available_money: null,
        retry: null,
        min_autoinvest_project_amount_mxn: null,
        max_autoinvest_project_amount_mxn: null,
        autoinvest_applicant_payments: null,
        autoinvest_deposits: null,
        suspendedDirectBillingBankAccount: null,
      },
      switchPlanOption: null,
      switchPlanValue: null,
    }
  },
  async mounted() {
    this.showLoading(true);
    this.isMounted = false;
    await this.loadPrefs();
    this.isMounted = true;
    this.showLoading(false);
  },
  methods: {
    async loadPrefs() {
      await this.getPlansInfo();
      await this.setDefaults();
    },
    async getPlansInfo() {
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`);
        this.investorPlan = res.data.plan;
      }
      catch (e) {
      }
    },
    confirmSwitchInvestmentPlanOption(option, value) {
      let message = "";
      let title = "";
      this.switchPlanOption = option;
      this.switchPlanValue = value;
      // si se va a activar no se muestra mensaje de confirmacion
      if (value) {
        this.switchInvestmentPlanOption();
        return;
      }
      let firstText = value == 1 ? "reactivar" : "pausar";
      let secondText = value == 1 ? "reactivado" : "pausado";
      let thirdText = firstText == "reactivar" ? "pausar" : "reactivar";
      switch (option) {
        case "autoinvest_applicant_payments":
          title = "¿Estás seguro que deseas " + firstText + " la inversión automática?";
          message = "El proceso de inversión automática será " + secondText + ", lo puedes " + thirdText + " en cualquier momento.";
          break;
        default:
          break;
      }
      this.successDialogWithCallbacks(
        this.switchInvestmentPlanOption,
        () => {
          if (option == "autoinvest_applicant_payments") {
            this.autoInvestPayments = !this.autoInvestPayments;
          } else if (option == "autoinvest_deposits") {
            this.directBillingisActive = true;
          }
        },
        title,
        message,
        "Confirmar",
        "Cancelar"
      );
    },
    async switchInvestmentPlanOption() {
      this.showLoading(true)
      try {
        let payload = {
          option: this.switchPlanOption,
          value: this.switchPlanValue,
          geolocation: null,
          contractType: null
        };
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v2/investor/${this.InvestorId}/switchInvestmentPlanOption`, payload);
        this.showLoading(true);
        this.isMounted = false;
        await this.loadPrefs();
        this.isMounted = true;
        this.showLoading(false);
        this.successNotif(
          "Operación exitosa",
          "Se han actualizado exitosamente tus preferencias"
        );
        this.$emit('automatic-investment-changed', this.switchPlanValue);
      }
      catch (e) {
        const mssg = this.catchError(e);
        this.errorNotif('Error en la operación', mssg);
      }
      this.showLoading(false);
    },
    catchError(e) {
      if (e?.response?.data?.error) {
        return e.response.data.error
      }
      else {
        return "No ha sido posible actualizar la información";
      }
    },
    async setDefaults() {
      this.autoInvestPayments = this.investorPlan.autoinvest_applicant_payments === 1;
    }
  }
}
</script>